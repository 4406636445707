

import * as singleSpa from 'single-spa';

import eventBus from './../event-bus';
import features from './../features';

import './../styles.scss';

import {
  showWhenPrefix,
  showExcept,
  showWhenAnyOfAndNotTaxfiler,
  showWhenPrefixWithParameters,
  showWhenClientViewRoute,
  showWhenSentaRoute,
  showWhenTaxfilerRoute,
  showWhenAnyOfOrHashMatch,
  showOnlyExactMatch,
  load
} from './../root-methods.util';

export default function appMain(environment) {
  console.log('hostApp: loading as practice user. app-main.js loaded.');

  const isProduction = /true/g.test(environment.production);
  const isTesting = /true/g.test(environment.testing);


  if (isProduction && !isTesting) {
    require('./../feature-toggle.scss');
  }
  singleSpa.registerApplication(
    'features',
    () => load('@iris/elements2-features-component-v01-pkg'),
    showExcept(['/login', '/signup', '/logout', '/missing-profile']),
    { environment, features },
  );

  singleSpa.registerApplication(
    'header',
    () => load('@iris/elements2-layout-header-component-v01-pkg', false),
    showExcept(['/login', '/signup', '/logout', '/missing-profile']),
    { environment, eventBus, features }
  );

  singleSpa.registerApplication(
    'footer',
    () => System.import('@iris/elements2-layout-footer-component-v01-pkg').then(m => m),
    showExcept(['/login']),
    { environment, eventBus, features }
  );

  singleSpa.registerApplication(
    'dashboard',
    () => load('@iris/elements2-dashboard-component-v01-pkg', false),
    showWhenAnyOfAndNotTaxfiler(['/', '/home']),
    { environment, eventBus, features }
  );

  singleSpa.registerApplication(
    'kyc',
    () => System.import('@iris/elements2-kyc-component-v01-pkg').then(m => m.default),
    showWhenPrefixWithParameters(['/kyc']),
    { environment, features },
  );

  singleSpa.registerApplication(
    'templates',
    () => System.import('@iris/elements2-templates-component-v01-pkg').then(m => m.default),
    showWhenPrefix(['/templates/aml/kyc']),
    { environment },
  );

  singleSpa.registerApplication(
    'administration',
    () => System.import('@iris/elements2-admin-component-v01-pkg').then(m => m.default),
    showWhenPrefix(['/administration']),
    { environment, features },
  );

  singleSpa.registerApplication(
    'riskassessment',
    () => System.import('@iris/elements2-riskassessment-component-v01-pkg').then(m => m.default),
    showWhenPrefixWithParameters(['/risk-assessment']),
    { environment },
  );

  singleSpa.registerApplication(
    'suspiciousactivity',
    () => System.import('@iris/elements2-suspiciousactivity-component-v01-pkg').then(m => m.default),
    showWhenPrefix(['/suspicious-activity']),
    { environment },
  );

  singleSpa.registerApplication(
    'practicecompliance',
    () => load('@iris/elements2-practicecompliance-component-v01-pkg'),
    showWhenPrefix(['/aml/practice-compliance', '/domain-link/risk/firmassessment']),
    { environment, features },
  );

  singleSpa.registerApplication(
    'client-account',
    () => load('@iris/tax-client-account-component-v01-pkg'),
    showWhenPrefix(['/client-account']),
    { environment, features },
  );

  singleSpa.registerApplication(
    "capital-assets",
    () => load("@iris/capitalassets-component-v01-pkg"),
    showWhenPrefixWithParameters(["/capital-assets/client/:id/"]),
    { environment, eventBus, features }
  );

  singleSpa.registerApplication(
    "capital-allowances",
    () => load("@iris/capitalassets-allowances-component-v01-pkg"),
    showWhenPrefixWithParameters(["/capital-allowances"]),
    { environment, eventBus, features }
  );

  singleSpa.registerApplication(
    'taxfiler',
    () => load('@iris/elements2-tax-filer-holder-component-v01-pkg'),
    showWhenTaxfilerRoute(),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'corporation-losses',
    () => load('@iris/tax-corporation-losses-component-v01-pkg'),
    showWhenPrefixWithParameters(['/corporation-tax/losses/client', '/corporation-tax/losses/client/:clientId', '/corporation-tax/losses/client/:clientId/tax-return/:taxReturnId']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'corporation-groupsandconsortiums',
    () => load('@iris/tax-corporation-groupsandconsortiums-component-v01-pkg'),
    showWhenPrefixWithParameters(['/corporation-tax/groupsandconsortiums/client', '/corporation-tax/groupsandconsortiums/client/:clientId', '/corporation-tax/groupsandconsortiums/client/:clientId/tax-return/:taxReturnId']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'corporation-loans-to-participators',
    () => load('@iris/tax-corporation-loans-to-participators-component-v01-pkg'),
    showWhenPrefixWithParameters(['/corporation-tax/loans/client', '/corporation-tax/loans/client/:clientId', '/corporation-tax/loans/client/:clientId/tax-return/:taxReturnId']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'corporation-tax-credits',
    () => load('@iris/tax-corporation-tax-credits-component-v01-pkg'),
    showWhenPrefixWithParameters(['/corporation-tax/tax-credits/client', '/corporation-tax/tax-credits/client/:clientId', '/corporation-tax/tax-credits/client/:clientId/tax-return/:taxReturnId']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'corporation-supplementary',
    () => load('@iris/tax-corporation-supplementary-component-v01-pkg'),
    showWhenPrefix(['/corporation-supplementary', '/corporation-tonnage', '/corporation-ring-fenced', '/corporation-restitution-tax']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'authorise-agent648',
    () => load('@iris/tax-client-authorise-agent-component-v01-pkg'),
    showWhenPrefix(['/authorise-agent']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'practicedetails',
    () => load('@iris/practice-detail-management-component-v01-pkg'),
    showWhenPrefix(['/practice-details']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'self-assessment',
    () => load('@iris/tax-client-return-component-v01-pkg'),
    showWhenPrefix(['/self-assessment/view-all', '/corporate/view-all']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'vat-view-all',
    () => load('@iris/tax-client-vat-component-v01-pkg'),
    showWhenPrefix(['/vat/view-all']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'mtd-view-all',
    () => load('@iris/tax-client-mtd-component-v01-pkg'),
    showWhenPrefix(['/mtd/obligations/view-all']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'partnership-shares',
    () => load('@iris/tax-partnership-shares-component-v01-pkg'),
    showWhenPrefix(['/partnership-shares']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'communications-websockets-app',
    () => load('@iris/communications-websockets-component-v01-pkg'),
    showExcept(['/login', '/signup', '/signup/', '/logout', '/missing-profile']),
    { environment, eventBus },
  );

  singleSpa.registerApplication(
    'communications-notifications-app',
    () => load('@iris/communications-notifications-component-v04-pkg'),
    isTesting ? showExcept(['/login', '/signup', '/logout', '/missing-profile', '/signup/']) : showExcept(['/login', '/logout', '/missing-profile']),
    { environment, eventBus },
  );

  singleSpa.registerApplication(
    'migration-tool',
    () => load('@iris/elements2-migration-tool-component-v01-pkg'),
    showWhenPrefix(['/data-migration-service']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'clients-prospects',
    () => load('@iris/elements2-clients-prospects-component-v02-pkg'),
    showWhenPrefix(['/client-management', '/client-management/', '/domain-link/risk/assessment', '/domain-link/ias']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'import-clients',
    () => load('@iris/elements2-clients-import-component-v01-pkg', false),
    showWhenAnyOfOrHashMatch(['/client-management/#import-clients', '/client-management/v2/#import-clients']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    '404',
    () => load('@iris/elements-404-component-v01-pkg'),
    showWhenPrefix(['/404']),
    { environment },
  );

  singleSpa.registerApplication(
    'user-management',
    () => load('@iris/platform-user-management-component-pkg'),
    showWhenPrefix(['/user-management', '/user/profile']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'company-formation',
    () => load('@iris/elements-company-formation-component-v00-pkg'),
    showWhenPrefix(['/company-formations']),
    { environment, features },
  );

  singleSpa.registerApplication(
    'global-settings',
    () => load('@iris/elements2-globalsettings-dashboard-component-v01-pkg'),
    showWhenPrefix(['/global-settings']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'external',
    () => load('@iris/elements2-taxfiler-layout-component-v01-pkg'),
    showWhenPrefix(['/external']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'accounts-production',
    () => load('@iris/elements-accounts-production-component-v00-pkg'),
    showWhenPrefix(['/accounts-production']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'accounts-builder',
    () => load('@iris/elements-accounts-builder-component-v01-pkg'),
    showWhenPrefixWithParameters(['/accounts-production/periods/:clientId/:periodId/accounts-builder']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'account-period-data',
    () => load('@iris/elements-account-period-data-component-v01-pkg'),
    showWhenPrefixWithParameters(['/accounts-production/periods/:clientId/:periodId/source-data', '/accounts-production/periods/:clientId/:periodId/adjustments', '/accounts-production/periods/:clientId/:periodId/import-wizard/:importId/import', '/accounts-production/periods/source-data/bookkeeping/callback']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'accounts-submission',
    () => load('@iris/elements-accounts-submission-component-pkg'),
    showWhenPrefixWithParameters(['/accounts-production/periods/:clientId/:periodId/approval', '/accounts-production/periods/:clientId/:periodId/submission']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'reporting',
    () => load('@iris/elements-reporting-component-v01-pkg'),
    showWhenPrefix(['/reporting']),
    { environment, features },
  );

  singleSpa.registerApplication(
    'senta-angularjs',
    () => System.import('@iris/elements2-senta-angular-client'),
    showWhenSentaRoute(),
    { environment, eventBus, features, domElementId: 'senta-angular-holder' }
  );

  singleSpa.registerApplication(
    'senta-angularjs-client-view',
    () => System.import('@iris/elements2-senta-angular-client'),
    showWhenClientViewRoute(),
    { environment, eventBus, features, domElementId: 'senta-angular-tab-holder' }
  );

  singleSpa.registerApplication(
    'senta-react-client-tabs',
    () => load('@iris/elements2-senta-react-client'),
    showWhenClientViewRoute(),
    { environment, eventBus, features, component: "client-view" }
  );

  singleSpa.registerApplication(
    'senta-react-external-download',
    () => load('@iris/elements2-senta-react-client'),
    showWhenPrefix(['/download', '/att']),
    { environment, eventBus, features, component: "download" }
  );

  singleSpa.registerApplication(
    'senta-react-import-export',
    () => load('@iris/elements2-senta-react-client'),
    showWhenPrefix(['/tools/import-export']),
    { environment, eventBus, features, component: "import-export" }
  );

  singleSpa.registerApplication(
    'senta-react-integrations-auth',
    () => load('@iris/elements2-senta-react-client'),
    showWhenPrefix(['/pm/integrations']),
    { environment, eventBus, features, component: "integrations-auth" }
  );

  singleSpa.registerApplication(
    'ipm-dashboard',
    () => load('@iris/ipm-dashboard-v01-pkg'),
    showWhenPrefix(['/proposal-management/dashboard']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'ipm-config',
    () => load('@iris/ipm-config-v01-pkg'),
    showWhenPrefix(['/proposal-management/config']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'ipm-proposal',
    () => load('@iris/ipm-proposal-v01-pkg'),
    showWhenPrefix(['/proposal-management/proposals']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'financial-performance',
    () => load('@iris/elements2-reporting-financial-performance-v01-pkg'),
    showWhenPrefix(['/financial-performance']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'cosec-main',
    () => load('@iris/cosec-main-v01-pkg', false),
    showWhenPrefix(['/company-secretarial']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'xbrl',
    () => load('@iris/xbrl-mapping-components-pkg'),
    showWhenPrefix(['/xbrl']),
    { environment, features },
  );

  singleSpa.registerApplication(
    'credit-score-results',
    () => System.import('@iris/elements2-capitalise-credit-score-results-pkg').then(m => m),
    showWhenPrefix(['/credit-score']),
    { environment, eventBus, features }
  );

  singleSpa.registerApplication(
    'import-wizard',
    () => load('@iris/integrations-clients-import-wizard-component-pkg'),
    showWhenPrefix(['/import-wizard/clients']),
    { environment, eventBus, features }
  );

  singleSpa.registerApplication(
    'profit-share',
    () => load('@iris/elements2-accountsproduction-profit-share-v01-pkg'),
    showWhenPrefixWithParameters(['/accounts-production/periods/:clientId/:periodId/profit-share']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'scenario-planning',
    () => load('@iris/tax-planning-scenario-component-v01-pkg'),
    showWhenPrefix(['/scenario-planning']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'accounts',
    () => load('@iris/elements2-taxfiler-accounts-pkg'),
    showOnlyExactMatch(['/accounts']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'tax-accounts-due-dates',
    () => load('@iris/elements2-tax-tax-accounts-due-dates-component-v01-pkg'),
    showOnlyExactMatch(['/tax-accounts-due-dates/view-all']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'ifm',
    () => load('@iris/elements2-integrations-thanos-v01-pkg'),
    showWhenPrefix(['/ifm', '/time-and-expenses']),
    { environment, eventBus, features }
  );

  singleSpa.registerApplication(
    'tax-checklist',
    () => load('@iris/elements2-tax-checklist-component-v01-pkg'),
    showOnlyExactMatch(['/external/global-settings/e-checklists']),
    { environment, eventBus, features },
  );

  singleSpa.registerApplication(
    'account-schedule',
    () => load('@iris/elements2-tax-corporation-accountschedule-pkg'),
    showOnlyExactMatch(['/account-schedule']),
    {environment, eventBus, features},
  );

  singleSpa.registerApplication(
    'iframe-loader',
    () => load('@iris/elements2-iframeloader-v01-pkg', false),
    showWhenPrefix(['/iframe-loader']),
    {environment, eventBus, features},
  );

  singleSpa.registerApplication(
    "business-review",
    () => load("@iris/reporting-businessreview-component-v01-pkg", false),
    showWhenPrefix(["/business-review"]),
    { environment, eventBus, features }
  );

  singleSpa.registerApplication(
    "business-monitoring",
    () => load("@iris/reporting-businessmonitoring-thresholds-pkg", false),
    showWhenPrefix(["/business-monitoring"]),
    { environment, eventBus, features }
  );

  singleSpa.registerApplication(
    'senta-react-send-bulk-email',
    () => load('@iris/elements2-senta-react-client'),
    showWhenPrefix(["/e/a"]),
    { environment, eventBus, features, component: "send-bulk-email" }
  );

  singleSpa.registerApplication(
    "cost-centres",
    () => load("@iris/elements2-accountsproduction-costcentre-component-pkg"),
    showWhenPrefixWithParameters([
      "/accounts-production/periods/:clientId/:periodId/cost-centres",
    ]),
    { environment, eventBus, features }
  );

  singleSpa.registerApplication(
    'test-harness',
    () => load('@iris/hmrc-test-harness-component-pkg'),
    showWhenPrefixWithParameters(['/hmrc-test-harness']),
    { environment, eventBus, features }
  );
}
